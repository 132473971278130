<template>
  <v-card :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{
              name: page_route,
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-form ref="form_layout" lazy-validation>
        <v-row dense>
          <v-col cols="12" xs="12" md="3">
            <v-select
              v-model="layout.id"
              label="Tipo"
              :items="layouts"
              item-value="id"
              :item-text="(v) => v.name"
              :rules="rules.required"
              dense
            />
          </v-col>
          <v-col cols="12" xs="12" md="3">
            <DatePicker
              label="Fecha de ingreso inicio"
              :model.sync="layout.start_date"
            />
          </v-col>
          <v-col cols="12" xs="12" md="3">
            <DatePicker
              label="Fecha de ingreso final"
              :model.sync="layout.end_date"
            />
          </v-col>
          <v-col cols="12" xs="12" md="3">
            <v-btn tile block color="info" @click="getData">
              <v-icon left v-text="'mdi-note-search'" />
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row dense v-if="data_table.length > 0">
        <v-col cols="12" xs="12" md="9">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar en tabla..."
            single-line
            hide-details
            dense
          />
        </v-col>
        <v-col cols="12" xs="12" md="3">
          <v-btn tile block color="success" @click="getFileXlsx">
            <v-icon left v-text="'mdi-file'" />
            Exportar Excel
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="data_table"
            :search="search"
            :items-per-page="15"
            dense
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgAlert,
  rules,
  dateTimeNow,
} from "../../control";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      page_route: "admission_request",
      login: this.$store.getters.getLogin,
      loading: false,
      rules: rules(),
      layouts: [
        {
          id: 1,
          name: "Cartas",
        },
      ],
      layout: {
        id: null,
        start_date: null,
        end_date: null,
      },
      data_table: [],
      search: "",
      headers: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      if (this.$refs.form_layout.validate()) {
        this.data_table = [];
        this.loading = true;
        let api = null;

        switch (this.layout.id) {
          case 1:
            api = "/rha/letters/report/list";

            this.headers = [
              { text: "ID", filterable: true, value: "folio" },
              { text: "CONTRATANTE", filterable: true, value: "contractor" },
              { text: "ID ASEGURADO", filterable: true, value: "enrollment" },
              {
                text: "ID MISIONERO",
                filterable: true,
                value: "missionary_id",
              },
              { text: "OFICINA", filterable: true, value: "office_address" },
              { text: "NOMBRE", filterable: true, value: "name" },
              { text: "A. PATERNO", filterable: true, value: "first_surname" },
              { text: "A. MATERNO", filterable: true, value: "second_surname" },
              { text: "MEDICO", filterable: true, value: "doctor" },
              { text: "FECHA CARTA", filterable: true, value: "created_at" },
              { text: "FOLIO", filterable: true, value: "folio_doctor" },
              { text: "MONTO", filterable: true, value: "amount" },
              { text: "DIAGNOSTICO", filterable: true, value: "diagnosis" },
              { text: "TIPO", filterable: true, value: "type" },
              { text: "NO. TAB", filterable: true, value: "no_tab" },
              { text: "TIPO CARTA", filterable: true, value: "accident" },
              { text: "TIPO INGRESO", filterable: true, value: "sinister" },
              { text: "FECHA NACIMIENTO", filterable: true, value: "birthday" },
            ];

            break;
        }

        Axios.post(
          URL_API + api,
          this.layout,
          headersToken(this.login.token)
        ).then((response) => {
          //console.log(response.data);
          this.data_table = response.data.data;

          if (this.data_table.length === 0) {
            this.$swal.fire(
              msgAlert(
                "warning",
                "No se encontro información con los parametros enviados"
              )
            );
          }

          this.loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    getFileXlsx() {
      this.loading = true;

      Axios.post(
        URL_API + "/rha/letters/report/excel",
        { letters: this.data_table },
        headersToken(this.login.token)
      ).then((response) => {
        //console.log(response.data);
        this.$swal.fire(
          msgAlert(
            response.data.success ? "success" : "error",
            response.data.message
          )
        );

        if (response.data.success) {
          const linkSource = `data:application/xlsx;base64,${response.data.data}`;
          const downloadLink = document.createElement("a");
          const fileName = `sm_layout_${response.data.now}.xlsx`;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }

        this.loading = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.layout.start_date = dateTimeNow().substring(0, 10);
    this.layout.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>
